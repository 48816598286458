import { useState, useCallback } from 'react'
import { ENDPOINT_URL } from './config'

/**
 * A component to display a post creation form and handle submitting it.
 */
export const MakePost = ({ submitSuccessCallback }) => {
  let [submitting, setSubmitting] = useState(false)
  let [error, setError] = useState(null)

  let [title, setTitle] = useState('')
  let [content, setContent] = useState('')
  let [username, setUsername] = useState('')

  const submitHandler = useCallback(
    async e => {
      if (submitting) {
        return
      }
      setError(null)
      setSubmitting(true)

      let post_obj = {
        title,
        content,
        username,
      }

      try {
        let res = await fetch(`${ENDPOINT_URL}/posts`, {
          method: 'POST',
          body: JSON.stringify(post_obj),
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })

        if (!res.ok) {
          let err = await res.text()
          throw new Error(err)
        }

        handleSuccess()
      } catch (e) {
        setError(e.message)
        alert(e.message)
      } finally {
        setSubmitting(false)
      }

      function handleSuccess() {
        if (submitSuccessCallback) {
          submitSuccessCallback(post_obj)
        }
        setTitle('')
        setContent('')
      }
    },
    [submitting, title, content, username, submitSuccessCallback],
  )

  return (
    <div className="make-post post-container">
      <div className="header">
        <span>Create a Post...</span>
        <button
          className={
            (submitting ? 'submitting' : '') + ' ' + (error ? 'error' : '')
          }
          onClick={submitHandler}
        >
          Submit
        </button>
      </div>
      <h2 className="post-title with-input">
        <input
          type="text"
          placeholder="(untitled)"
          value={title}
          onChange={evt => setTitle(evt.target.value)}
        />
      </h2>
      <div className="post-username with-input">
        <span>By:&nbsp;</span>
        <input
          type="text"
          placeholder="(anonymous)"
          value={username}
          onChange={evt => setUsername(evt.target.value)}
        />
      </div>
      <textarea
        className="content-input"
        value={content}
        placeholder="Insert Markdown here!"
        onChange={evt => setContent(evt.target.value)}
      ></textarea>
    </div>
  )
}
