import { ENDPOINT_URL } from './config'
import { useAsync } from 'react-use'
import { Post } from './Post'

async function fetchPosts() {
  const res = await fetch(`${ENDPOINT_URL}/posts`, { method: 'GET' })
  if (!res.ok) {
    throw new Error(await res.text())
  }
  const json = await res.json()
  return json
}

/**
 * A component to display a loaded list of posts.
 */
export const Posts = ({ posts, isLocal }) => {
  return (
    <>
      {posts
        .sort((a, b) => {
          let a_time = new Date(a.createdAt).getTime()
          let b_time = new Date(b.createdAt).getTime()
          return Math.sign(b_time - a_time)
        })
        .map(post => (
          <Post key={post.id} post={post} isLocal={isLocal} />
        ))}
    </>
  )
}

/**
 * A component which handle loading and displaying the list of posts.
 */
export const PostList = () => {
  const { loading, error, value: posts } = useAsync(() => fetchPosts(null))

  return (
    <div className="post-list">
      {loading && <div>Loading...</div>}
      {error && <div className="error">{error.message}</div>}
      {posts && (
        <>
          <div className="posts-count">Loaded {posts.length} posts</div>
          <Posts posts={posts} />
        </>
      )}
    </div>
  )
}
