import './App.css'

import { PostList, Posts } from './PostList'
import { MakePost } from './MakePost'
import { useState } from 'react'

function App() {
  // A list of user created posts, so that we can show their post in the list immediately without waiting for cache to update.
  const [localPosts, setLocalPosts] = useState([])

  function augmentPostData(post) {
    post.createdAt = new Date().toISOString()
    return post
  }

  return (
    <div className="App">
      <MakePost
        submitSuccessCallback={post =>
          setLocalPosts([augmentPostData(post), ...localPosts])
        }
      />
      {localPosts.length > 0 && <Posts posts={localPosts} isLocal={true} />}
      <PostList />
    </div>
  )
}

export default App
