import ReactMarkdown from 'react-markdown'

export const TextOrPlaceholder = ({ text, placeholder }) => {
  if (text) {
    return text
  } else {
    return <span className="placeholder">{placeholder}</span>
  }
}

export const Post = ({ post, isLocal }) => {
  return (
    <div className="post-container">
      {isLocal && (
        <div className="success-msg">
          Your post has been successfully created! However it may take up to 60
          second for it to appear for other people, due to KV caching.
        </div>
      )}
      <h2 className="post-title">
        <TextOrPlaceholder text={post.title} placeholder="(untitled)" />
      </h2>
      <div className="post-username">
        By: <TextOrPlaceholder text={post.username} placeholder="(anonymous)" />
        , on {new Date(post.createdAt).toLocaleString()}
      </div>
      <ReactMarkdown>{post.content}</ReactMarkdown>
    </div>
  )
}
